<template>
    <div class="about">
        <carousel
			:nav = true
			:centerMode = true
			:autoPlay = true
			:playSpeed = 8000
			:dtImg = banner
		/>

        <tabMenu 
			:csrLang=csrLang 
			:lang=lang
			:csrYear=csrYear
			:csr=csr

		/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    components:{
        carousel : () => import ("@/components/carousel"),
        tabMenu : () => import ("@/components/csr/TabMenu"),
    },
	computed: {
		...mapState({
            data: state => state.home.home ? state.home.home.data :'',
			lang: state => state.home.lang ? state.home.lang :'id',
			csr: state => state.home.csr ? state.home.csr.data :'',
        }),
		csrLang(){
			let csrTab_id = {}
			let csrTab_eng= {}

			if (this.lang == 'id'){
				Object.assign(csrTab_id, 
					{environment: this.data.environment},
					{safety: this.data.safety},
					{society: this.data.society},
					{sustainability: this.data.sustainability}
				);
				return csrTab_id
			}else{
				Object.assign(csrTab_eng, 
					{environment: this.data.environment_eng},
					{safety: this.data.safety_eng},
					{society: this.data.society_eng},
					{sustainability: this.data.sustainability_eng}
				);
				return csrTab_eng
			}
		},
		csrYear(){
			const result = this.csr.map((item) => {
				return item.year
			})

			return result.sort((a, b) => a > b ? -1 : 1);
		},
		banner(){
			return this.$store.getters['image/imgWtFilter']('csr_banner')
		}
	}
}
</script>